:root {
  --easing-authentic-motion: cubic-bezier(0.4, 0, 0.2, 1);
  --transition-delay: 1.2s;
}

.visible {
  transform: translateY(0);
}

.hidden {
  transform: translateY(-300%);
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-inner {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  margin: 0;
  padding: 0 0 0 40px;
  transition: transform 0.5s ease-in-out;
  position: relative;
  z-index: 600;
}

.logo-text {
  font-size: 24px;
}

.color {
  color: #6a30a8;
}

.bg-color {
  width: 100%;
  height: 100px;
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
  transition: transform 0.5s ease-in-out;
}

.logo-icon {
  height: 30px;
}

h2 .title {
  font-weight: 600;
}

.menu-text-wrapper {
  cursor: pointer;
  height: 30px;
  width: fit-content;
  color: white;
  display: none;
  position: relative;
  z-index: 250;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;
}

ul {
  list-style: none;
  padding: 30px 40px;
}

.menu-vertical {
  position: fixed;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  width: 100%;
  height: 100vh;
  background-color: #fff;
  margin: 0;
  padding: 0;
  transition: transform 0.6s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.open {
  transform: translateY(0);
}

.close-icon {
  position: fixed;
  top: 28px;
  right: 10px;
  z-index: 777;
  cursor: pointer;
  font-size: 36px;
  color: #6a30a8;
}

.logo-icon-wrapper {
  position: fixed;
  z-index: 1000;
  top: 42px;
  left: 40px;
  transition: all 0.4s var(--easing-authentic-motion);
}

.menu-horizontal {
  display: flex;
  width: 250px;
  justify-content: space-between;
}

@media (width <=768px) {
  .menu-text-wrapper {
    display: block;
    position: fixed;
    top: 40px;
    right: 22px;
  }

  .logo-icon-wrapper {
    position: fixed;
    top: 32px;
    left: 20px;
  }

  .menu-horizontal {
    display: none;
  }

  .menu-text-wrapper h4 {
    margin: 0;
    font-weight: 500;
  }

  .bg-color {
    height: 80px;
  }
}

.vertical {
  margin-bottom: 30px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  color: #fff;
  position: relative;
  line-height: 1.5;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;
}

.horizontal .title {
  transition: color 1.2s;
}

.vertical .title {
  font-size: 36px;
  color: #6a30a8;
}

.vertical .title:hover {
  color: #f60;
}

.horizontal .title::after {
  content: '';
  display: block;
  margin: auto;
  height: 1px;
  width: 0;
  top: 5px;
  background: transparent;
  transition: all 0.3s;
}

.horizontal .title:hover::after {
  width: 100%;
  background: #fff;
}

.horizontal.purple .title {
  color: #6a30a8;
}

.horizontal.purple .title:hover::after {
  width: 100%;
  background: #6a30a8;
}

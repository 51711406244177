@keyframes textclip {
  0% {
    background-position: 0% center;
  }

  100% {
    background-position: 100% center;
  }
}

@keyframes slideIn {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
}

@keyframes wave {
  0%,
  100% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(20deg);
  }
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-10px, -10px);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(10px, 10px);
  }
}

.none {
  display: none;
}

#circle {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
}

#circle svg {
  width: 300px;
  height: 300px;
  animation-name: rotate;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.arrow {
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
  transform: rotate(360deg);
  cursor: pointer;
}

.arrow span {
  display: block;
  width: 15px;
  height: 15px;
  border-bottom: 3px solid white;
  border-right: 3px solid white;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

.shape::before {
  position: absolute;
  content: '';
  transform-origin: 100% 100%;
  border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
  will-change: border-radius, transform, opacity;
  display: block;
  z-index: -2;
  animation: sliderShape 5s linear infinite;
}

.shape-one::before {
  right: -5%;
  top: 20%;
  width: 450px;
  height: 450px;
  background-image: linear-gradient(-225deg, #0667a8, #8e44ad, #e74c3c);
}

.shape-two::before {
  left: -8%;
  bottom: 0%;
  width: 350px;
  height: 350px;
  background-image: linear-gradient(-225deg, #0667a8, #8e44ad, #ffc750);
}

@keyframes sliderShape {
  0%,
  100% {
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }

  34% {
    border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
  }

  50% {
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }

  67% {
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
    transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0);
  }
}

.intro-home-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.intro-full {
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 15px;
}

.intro-text-one,
.intro-text-two,
.intro-text-three {
  transition: font-size 0.3s ease 0.1s;
  color: #fff;
  margin: 0;
  line-height: 1.5;
}

.slide-in {
  opacity: 0;
  animation: slideIn 1.5s ease forwards;
}

.top {
  transform: translateY(-100%);
}

.bottom {
  transform: translateY(100%);
}

.intro-text-one {
  font-size: 50px;
  font-weight: 500;
  color: #fff800;
}

.intro-text-two {
  font-weight: 700;
  font-size: 90px;
  line-height: 1;
  background-image: linear-gradient(
    -225deg,
    #322170 0%,
    #582194 59%,
    #fd2c71 67%,
    #fff800 100%
  );
  background-size: 200% auto;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear forwards;
  white-space: nowrap;
}

.intro-text-three {
  font-size: 28px;
  font-weight: 400;
  color: #fff;
}

.intro-home {
  min-height: 100vh;
  overflow: hidden;
  position: relative;
}

.page-home a {
  text-decoration: none;
}

.side {
  position: fixed;
  transform-origin: 100%, 100%;
  bottom: 6%;
  right: 2.5%;
  z-index: 999;
}

.intro-link {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.side .intro-link {
  flex-direction: column;
}

.intro-link > div {
  padding: 5px;
}

.btn {
  cursor: pointer;
  animation: wave 3s infinite;
}

.contact-btn {
  margin-bottom: 100px;
}

.project-btn a {
  display: flex;
  gap: 5px;
  align-items: center;
  color: #fff800;
}

.project-btn-text,
.contact-btn-text {
  font-weight: 500;
}

.contact-btn-icon {
  margin-top: 3px;
}

.contact-btn a {
  display: flex;
  gap: 5px;
  align-items: center;
  color: #fff;
}

.project-btn > div,
.contact-btn > div {
  padding: 10px;
  cursor: pointer;
}

.project-btn > div {
  border: 2px solid #fff800;
}

.contact-btn > div {
  border: 2px solid #fff;
}

.project-btn > div:hover,
.contact-btn > div:hover {
  background-color: #0667a8;
  border-color: #0667a8;
  outline: none;
}

.intro-link > div a {
  color: #fff;
}

.intro-link > div:hover a {
  color: #fff800;
}

.link-btn-icon {
  margin-top: 1px;
  font-size: 28px;
}

.project-btn,
.contact-btn {
  display: flex;
  gap: 10px;
}

.container {
  padding: 80px 20px 40px;
}

.section-title {
  color: #fff800;
  font-size: 100px;
  font-weight: 600;
  margin: 0 0 60px;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.section-introduction {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.6;
  margin: 0;
}

.section-description {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.6;
  margin: 0;
}

.icon {
  width: 90px;
  height: 90px;
  background-size: cover;
  animation: float 3s ease-in-out infinite;
}

.row.two {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 50px;
  align-items: center;
  margin-bottom: 120px;
  position: relative;
}

.profile {
  display: grid;
  grid-template-columns: 1fr 1.8fr;
  gap: 50px;
  align-items: center;
}

.profile-image-wrapper-desktop {
  position: relative;
  overflow-x: hidden;
}

.profile-image-wrapper-mobile img {
  display: none;
}

.profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.row.two.opposite {
  grid-template-columns: 1.5fr 1fr;
}

h5 {
  font-size: 60px;
  color: #fff;
  font-weight: 500;
  margin: 0;
}

.column-stack {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.translate {
  transform: translateX(-100%);
  transition: transform 1.5s ease-in-out;
}

.project .section-title {
  text-align: left;
}

.project-title {
  line-height: 1.3;
}

.project-number {
  position: absolute;
  z-index: -1;
  font-size: 13rem;
  font-weight: 600;
  left: 25%;
  color: #fff;
  opacity: 0.1;
  margin: 40px 0 0;
  transition: font-size 0.3s ease 0.1s;
}

.project-number.right {
  left: 80%;
}

.project-number.three {
  left: 21%;
}

.column.one {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.row.two .column.one {
  padding: 0 0 0 50px;
}

.row.two.opposite .column.one {
  padding: 0 50px 0 0;
}

.about-me {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.about-me,
.contact {
  padding: 80px 100px 40px;
}

.about-me .section-title {
  margin: 0 0 20px;
}

.about-me .column {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  gap: 30px;
}

.image-wrapper {
  display: flex;
}

.image-link {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.column-image {
  width: 100%;
  position: relative;
  overflow: hidden;
  transform: scale(0.5);
  transition: transform 1s ease;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
  z-index: 1;
  transition: opacity 0.3s;
  border-radius: inherit;
}

.image-link:hover .image-overlay {
  opacity: 0.1;
}

.column-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.contact-message {
  color: #fff;
  font-size: 28px;
  font-weight: 400;
  line-height: 1.6;
}

.keyword {
  color: #fff800;
  font-weight: 500;
}

.custom-cursor {
  position: fixed;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff800;
  pointer-events: none;
  z-index: 9999;
  transform: translate(-50%, -50%);
  transition: transform 0.1s ease;
}

@media (width > 1800px) {
  .intro-text-one {
    font-size: 70px;
  }

  .intro-text-two {
    font-size: 110px;
  }

  .intro-text-three {
    font-size: 45px;
  }

  .row.two,
  .row.two.opposite {
    grid-template-columns: 1fr 1fr;
    gap: 100px;
  }

  .about-me,
  .contact {
    padding: 80px 200px;
  }

  .project-number {
    font-size: 16rem;
    font-weight: 600;
    left: 25%;
    margin: 60px 0 0;
  }
}

@media (width <=1200px) {
  .intro-text-one {
    font-size: 40px;
  }

  .intro-text-two {
    white-space: pre-wrap;
    font-size: 75px;
  }

  .intro-text-three {
    font-size: 24px;
  }

  .section-title {
    font-size: 75px;
  }

  .project-number {
    font-size: 10rem;
  }

  h5 {
    font-size: 45px;
  }

  .icon {
    width: 65px;
    height: 65px;
  }

  .profile {
    display: block;
  }

  .profile-image-wrapper-desktop {
    position: absolute;
    width: 30%;
    min-width: 250px;
    bottom: -10%;
    right: 10%;
    z-index: -1;
  }

  .contact-message {
    font-size: 24px;
  }

  #circle {
    left: 80%;
  }
}

@media (width >= 577px) and (width <= 768px) {
  .intro-head,
  .intro-text {
    transition: font-size 0.3s ease 0.1s;
  }

  .intro-text-one {
    font-size: 32px;
  }

  .intro-text-two {
    font-size: 60px;
  }

  .intro-text-three {
    font-size: 18px;
  }

  .column.one {
    gap: 12px;
  }

  .side {
    display: none;
  }

  .project .column.one {
    width: 100%;
  }

  .project-number {
    display: none;
  }

  #circle {
    left: 80%;
  }

  .connect-icon {
    font-size: 24px;
  }

  .connect-message {
    font-size: 18px;
  }

  .link-btn-icon {
    font-size: 24px;
  }

  .contact-btn {
    flex-wrap: wrap;
  }

  .container {
    padding: 80px 25px 40px;
  }

  .section-title {
    font-size: 50px;
    margin-bottom: 30px;
  }

  .about-me .section-title {
    margin: 0;
  }

  h5 {
    font-size: 28px;
  }

  .section-introduction {
    font-size: 18px;
  }

  .section-description {
    font-size: 18px;
  }

  .row.two {
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
    margin-bottom: 60px;
  }

  .row.two .column.one,
  .row.two.opposite .column.one {
    padding: 0;
  }

  .row.two.opposite {
    flex-direction: column;
  }

  .contact-message {
    font-size: 20px;
  }

  .profile-image-wrapper-desktop img {
    display: none;
  }

  .profile-image-wrapper-mobile {
    position: absolute;
    width: 30%;
    min-width: 250px;
    top: 0;
    left: 0;
    z-index: -1;
    transform: translate(-12%, -10%);
  }

  .profile-image-wrapper-mobile img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (width <= 576px) {
  .intro-text-one {
    font-size: 32px;
  }

  .intro-text-two {
    font-size: 60px;
  }

  .intro-text-three {
    font-size: 18px;
  }

  .side {
    display: none;
  }

  .column.one {
    gap: 12px;
  }

  .project .column.one {
    width: 100%;
  }

  .project-number {
    display: none;
  }

  .shape-one::before {
    top: 20%;
    width: 150px;
    height: 150px;
  }

  .shape-two::before {
    bottom: 20%;
    width: 200px;
    height: 200px;
    background-image: linear-gradient(-225deg, #0667a8, #8e44ad, #ffc750);
  }

  .connect-icon {
    font-size: 24px;
  }

  .connect-message {
    font-size: 18px;
  }

  .link-btn-icon {
    font-size: 24px;
  }

  .contact-btn {
    flex-wrap: wrap;
  }

  .container {
    padding: 80px 20px 40px;
  }

  .section-title {
    font-size: 50px;
    margin-bottom: 30px;
  }

  .about-me .section-title {
    margin: 0;
  }

  h5 {
    font-size: 28px;
  }

  .section-introduction {
    font-size: 18px;
  }

  .section-description {
    font-size: 18px;
  }

  .row.two {
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
    margin-bottom: 60px;
  }

  .row.two .column.one,
  .row.two.opposite .column.one {
    padding: 0;
  }

  .row.two.opposite {
    flex-direction: column;
  }

  .contact-message {
    font-size: 20px;
  }

  .profile-image-wrapper-desktop img {
    display: none;
  }

  .profile-image-wrapper-mobile {
    position: absolute;
    width: 30%;
    min-width: 250px;
    top: 0;
    left: 0;
    z-index: -1;
    transform: translate(-12%, -10%);
  }

  .profile-image-wrapper-mobile img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #circle {
    display: none;
  }

  .custom-cursor {
    display: none;
  }
}
